import { useEffect, useRef, useState } from "react";

const tempData = [
    {
        nazwa: "komputer",
        cena: 6000,
        przecena: null,
        img: "img/komputer.jpg",
        opis: "szybki i elegancki komputer, swietny do pracy biurowej",
        id: 2
    },
    {
        nazwa: "lampa",
        cena: 100.99,
        przecena: null,
        img: "img/lampa.jpg",
        opis: "por?czna i energooszcz?dna lampka biurowa, ?wietna do czytania ksi??ek",
        id: 1
    },
    {
        nazwa: "kupcia",
        cena: 44,
        przecena: null,
        img: "img/5lampa.jpg",
        opis: null,
        id: 5
    },
    {
        nazwa: "ksiazka",
        cena: 39.99,
        przecena: 19.99,
        img: "img/ksiazka.jpg",
        opis: "ksi??ka przygodowa uznanego pisarza, ciekawa i trzymaj?ca w napi?ciu",
        id: 3
    },
    {
        nazwa: "ksiazka 2",
        cena: 29.99,
        przecena: 9.99,
        img: "img/ksiazka.jpg",
        opis: "kolejna czesc zajebistych przygod bohatera ksiazki, tym razem walczy ze strasznym demonem ?mierci",
        id: 4
    }
]


function Toolbar({ children }) {
    return (
        <div className="toolbar">
            {children}
        </div>
    )
}

function useAPI(targetURL = "https://www.ardineo.pl/stock.php", searchParams = "", emptyDependency = false) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [result, setResult] = useState([])

    useEffect(function () {
        const url = new URL(targetURL)
        const params = new URLSearchParams(searchParams)

        url.search = params

        const controller = new AbortController()

        async function pobierzDane() {
            try {
                setIsLoading(true)
                const res = await fetch(url, { signal: controller.signal, method: "GET", credentials: "same-origin" })
                const json = await res.json()

                setResult(json)
            }
            catch (e) {
                setError(e.message)
            }
            finally {
                setIsLoading(false)
            }
        }
        pobierzDane()

        return function () {
            controller.abort()
        }
    }, emptyDependency ? [] : [searchParams, targetURL])

    return [isLoading, error, result]
}

function Items({ searchParams }) {

    const [
        isLoading, error, result
    ] = useAPI("https://www.ardineo.pl/stock.php", searchParams)

    //if (error) return <ul className="productsList"><p style={{ textAlign: "center", fontSize: "2rem" }}>Połączenie z bazą nieudane🙄</p></ul>
    if (isLoading) return <ul className="productsList"><Loader></Loader></ul>
    if (result.length === 0) return <ul className="productsList"><p style={{ textAlign: "center", fontSize: "2rem" }}>Brak wyników😐</p></ul>


    return (
        <ul className="productsList">
            {result.map(item => {

                const {
                    nazwa,
                    cena,
                    przecena,
                    img,
                    opis,
                    id
                } = item

                return (
                    <li className="product">
                        <div className="productData">
                            <div className="productImage">
                                <img src={img} alt={`Zdjęcie ${nazwa}`} />
                            </div>
                            <div className="productDetails">
                                <h3 className="productName">
                                    {nazwa}
                                    {przecena && <span style={{ color: "red", fontSize: "1rem", backgroundColor: "yellow", padding: "3px" }}>przecena!</span>}
                                </h3>
                                <p>{opis}</p>
                            </div>
                        </div>
                        <div className="productPrice">
                            {przecena
                                ?
                                <p>
                                    <span className="oldPrice">{cena}</span>
                                    <span className="price">{przecena} zł</span>
                                </p>
                                :
                                <p>
                                    <span className="price">{cena} zł</span>
                                </p>}
                            <button className="addToCart">Dodaj do koszyka</button>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export function Stock() {
    const [orderBy, setOrderBy] = useState("popularity");
    const [minPrice, setMinPrice] = useState("")
    const [maxPrice, setMaxPrice] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [numberOfResults, setNumberOfResults] = useState(20)
    const [categorie, setCategorie] = useState("")

    function handleChangeMinPrice(value) {
        setMinPrice(value !== 0 ? value : "")
        if (value >= maxPrice) setMaxPrice(value + 1)
    }

    function handleChangeMaxPrice(value) {
        setMaxPrice(value !== 0 ? value : "")
        if (value <= minPrice) setMinPrice(value - 1)
    }

    return (
        <div className="stock">
            <Toolbar>
                <div className="formGroup">
                    <div>
                        <label value={orderBy} onChange={(e) => setOrderBy(e.target.value)}>Sortuj </label>
                        <select>
                            <option value="price">według ceny</option>
                            <option value="date">według daty wystawienia</option>
                            <option value="popularity">według popularności</option>
                        </select>
                    </div>
                    <div>
                        <label>Zakres cen </label>
                        <input type="number" style={{ width: "50px" }} value={minPrice} onChange={e => handleChangeMinPrice(Number(e.target.value))}></input>
                        <span> - </span>
                        <input type="number" style={{ width: "50px" }} value={maxPrice} onChange={e => handleChangeMaxPrice(Number(e.target.value))}></input>
                    </div>
                    <div>
                        <label>Liczba rezultatów: {numberOfResults}</label><br></br>
                        <input
                            type="range"
                            min={0}
                            max={100}
                            value={numberOfResults}
                            onChange={e => setNumberOfResults(Number(e.target.value))}></input>
                    </div>
                </div>
                <input
                    className="search"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    placeholder="🔎Wyszukaj..."
                ></input>
            </Toolbar>
            <CategoriesList category={categorie} onChangeCategorie={setCategorie} />
            <Items
                searchParams={{
                    orderBy,
                    minPrice,
                    maxPrice,
                    searchQuery,
                    numberOfResults
                }}>

            </Items>
        </div>
    );
}

function CategoriesList({ category, onChangeCategorie }) {

    const [
        isLoading,
        error,
        result
    ] = useAPI("https://ardineo.pl/categoriesStats.php", {}, true)



    return (
        <div className="categoriesList">
            {isLoading ? <Loader></Loader> :
                <>
                    <h2>Kategorie</h2>
                    <ul>
                        {result.map(kategoria => {
                            const [nazwa, liczba] = Object.entries(kategoria)[0]
                            return <li className={`category ${category === nazwa ? "active" : ""}`} onClick={() => onChangeCategorie(nazwa)}>{nazwa}({liczba})</li>
                        })}
                    </ul>
                </>}
        </div>
    )
}

function Loader() {
    return <div className="loader"></div>
}