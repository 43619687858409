import { Header } from "./Header";
import { Stock } from "./Stock";

function App() {
  return (
    <div className="App">
      <Header>
        Jesteśmy w środku świątecznej promocji🎁! Przeceny nawet do -60%!
      </Header>
      <Stock />
    </div>
  );
}

export default App;


